let config = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "forwood-demo-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-demo-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-west-2",
        URL: "https://fjpzipd32nbbrkayaiwennhkwa.appsync-api.us-west-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://rt9zf0vgik.execute-api.us-west-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://4v2nbh59oa.execute-api.us-west-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.demo.forwoodsafety.com",
        WEBSOCKET: "wss://4ttf2gjoi3.execute-api.us-west-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_q8Ky24z37",
        IDENTITY_POOL_ID: "us-west-2:aad6e3a5-7455-49a5-a704-7adafaa75445",
        USERPOOL_HOSTED_DOMAIN: "forwood-demo-id-prod",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.demo.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.demo.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::731156719413:role/prod-CA"
    },
    samlProvider: {
      NAME: 'ForwoodAzure',
      SUPPORTED_LIST: 'ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.13",
        HOSTNAME: "id.demo.forwoodsafety.com",
        ENV_NAME: "prod",
        COOKIE_DOMAIN: ".demo.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "e02c46a1-d0d8-4b22-90e1-a624d0ac09be",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.demo.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
